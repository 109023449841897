import {createContext, useContext} from 'react';

import type {LocaleInfo} from './i18n/getLocale';

export const LocaleContext = createContext<LocaleInfo | null>(null);

export function useLocale(): LocaleInfo {
    const context = useContext<LocaleInfo | null>(LocaleContext);
    if (!context) {
        throw new Error(
            'useLocale must be used within a LocaleContextProvider',
        );
    }
    return context;
}
